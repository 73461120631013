import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const BasicElements = lazy(() => import("./form-elements/BasicElements"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Mdi = lazy(() => import("./icons/Mdi"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./auth-pages/Login"));
const Register1 = lazy(() => import("./auth-pages/Register"));

const UserDashbaord = lazy(() => import('./user-pages/dashboard/Dashboard'));
const MasterManager = lazy(() => import('./user-pages/master/MasterManager'));
const Master = lazy(() => import('./user-pages/master/Master'));
const UserManager = lazy(() => import('./user-pages/user/UserManager'));
const User = lazy(() => import('./user-pages/user/User'));


class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* Custom pages for Winnwin */}

                    <Route exact key="Dashboard" path="/dashboard" component={UserDashbaord} />
                    <Route exact key="MasterManager" path="/master-manager" component={MasterManager} />
                    <Route exact key="MasterManagerList" path="/master/:userid" component={Master} />
                    <Route exact key="UserManager" path="/user-manager" component={UserManager} />
                    <Route exact key="UserManagerList" path="/user/:userid" component={User} />

          {/* Theme default pages */}
          <Route exact path="/dashboard-default" component={Dashboard} />

          <Route path="/basic-ui/buttons" component={Buttons} />
          <Route path="/basic-ui/dropdowns" component={Dropdowns} />
          <Route path="/basic-ui/typography" component={Typography} />

          <Route
            path="/form-Elements/basic-elements"
            component={BasicElements}
          />

          <Route path="/tables/basic-table" component={BasicTable} />

          <Route path="/icons/mdi" component={Mdi} />

          <Route path="/charts/chart-js" component={ChartJs} />

          <Route path="/auth-pages/login" component={Login} />
          <Route path="/auth-pages/register" component={Register1} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          <Redirect to="/auth-pages/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
