import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useQuery } from "react-query";
import { ValidateToken } from '../../services/backendServices';


class Sidebar extends Component {
    // var user_id = localStorage.getItem('user_id');
    state = { isAuthenticated: false };

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.checkAuth();
            if (!this.state.isAuthenticated){
                this.onRouteChanged();
            }
        }
    }

    checkAuth() {
        // let access_token = localStorage.getItem("access_token");
        // // Check if token exists
        // if (access_token) {
        //     console.log("rroute : ",access_token)
        //     // Make an API request to validate the token
        //     const { data, error, isLoading } = useQuery("ValidateToken", async () =>
        //         ValidateToken(access_token).then((res) => {
        //             console.log("res", Object.keys(JSON.parse(res)).length === 0)
        //             if (Object.keys(JSON.parse(res)).length === 0) {
        //                 this.setState({ isAuthenticated: true });
        //             } else {
        //                 this.props.history.push('/login');
        //             }
        //         })
        //     );
        // } else {
        //     // Token does not exist
        //     this.setState({ isAuthenticated: false });
        //     // Redirect the user to the login page
        //     this.props.history.push('/login');
        // }
    }

    onRouteChanged() {
        document.querySelector('#sidebar').classList.remove('active');
        Object.keys(this.state).forEach(i => {
            this.setState({ [i]: false });
        });

        const dropdownPaths = [
            { path: '/apps', state: 'appsMenuOpen' },
            { path: '/basic-ui', state: 'basicUiMenuOpen' },
            { path: '/form-elements', state: 'formElementsMenuOpen' },
            { path: '/tables', state: 'tablesMenuOpen' },
            { path: '/icons', state: 'iconsMenuOpen' },
            { path: '/charts', state: 'chartsMenuOpen' },
            { path: '/auth-pages', state: 'userPagesMenuOpen' },
            { path: '/error-pages', state: 'errorPagesMenuOpen' },
        ];

        dropdownPaths.forEach((obj => {
            if (this.isPathActive(obj.path)) {
                this.setState({ [obj.state]: true })
            }
        }));

    }

    render() {
        return (
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
                    <a className="sidebar-brand brand-logo" href="index.html"><img src={require('../../assets/images/winnwin.png')} alt="logo" /></a>
                    <a className="sidebar-brand brand-logo-mini" href="index.html"><img src={require('../../assets/images/winnwin.png')} alt="logo" /></a>
                </div>
                <ul className="nav">
                    <li className="nav-item nav-category">
                        <span className="nav-link"><Trans>Admin</Trans></span>
                    </li>
                    <li className={this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                        <Link className="nav-link" to="/dashboard">
                            <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
                            <span className="menu-title"><Trans>Dashboard</Trans></span>
                        </Link>
                    </li>
                    <li className="nav-item nav-category">
                        <span className="nav-link"><Trans>Account Management</Trans></span>
                    </li>
                    <li className={this.isPathActive('/master-manager') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                        <Link className="nav-link" to="/master-manager">
                            <span className="menu-icon"><i className="mdi mdi-laptop"></i></span>
                            <span className="menu-title"><Trans>Master</Trans></span>
                        </Link>
                    </li>
                    <li className={this.isPathActive('/user-manager') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
                        <Link className="nav-link" to="/user-manager">
                            <span className="menu-icon"><i className="mdi mdi-laptop"></i></span>
                            <span className="menu-title"><Trans>User</Trans></span>
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }

    componentDidMount() {
        this.onRouteChanged();
        // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body');
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open');
                }
            });
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open');
                }
            });
        });
    }

}

export default withRouter(Sidebar);