import { API_URL } from "../Util/constant";
import jwt_decode from "jwt-decode";

export async function makeApiRequest(path, params) {
    var requestOptions = {
        method: 'POST',
        body: params,
        headers: { 'Content-Type': 'application/json' },
        redirect: 'follow'
    };
    const response = await fetch(`${API_URL}${path}`, requestOptions)
        .then(response => response.text())
        .then(result => { return result })
        .catch(error => { return error });
    return response
}

//   export async function makeApiRequest(path, params, content_type) {
//     try {
//       try {
//         const response = await fetch(`${API_URL}${path}`, {
//           method: "POST",
//           body: params,
//           headers: { 'Content-Type': 'application/json' },
//           // mode:'no-cors',
//           redirect: 'follow',
//         });

//         const rresponse = await response.json();
//         if (!rresponse.detail) {
//           return { result: rresponse };
//         } else {
//           return { error: rresponse.error.data };
//         }
//       } catch (err) {
//         console.error(err);
//         return {
//           error: {
//             title: "Something went wrong",
//             message: "Something went wrong",
//           },
//         };
//       }
//     } catch (error) {
//       console.error(error);
//       return {
//         error: {
//           title: "Api request error",
//           message: "Api request error",
//           error: error,
//         },
//       };
//     }
//   }
  

export async function ValidateToken(
    token,
) {
    const params = JSON.stringify({
        "token": token
    });
    // const params = {
    //     token: token
    // };

    const response = await makeApiRequest(
        "/auth/token/verify/",
        params
    );
    if (response) {
      return response;
    } else if (response.detail) {
      throw new Error(response.detail);
    } else {
      throw new Error("Unknown error. Please try again.");
    }
}

export async function Authenticate(
    username,
    password
) {
    const params = JSON.stringify({
        "username": username,
        "password": password
    });

    const response = await makeApiRequest(
        "/auth/token/",
        params
    );

    if (response) {
        const rresponse = JSON.parse(response)
        if (rresponse.access && rresponse.refresh) {
            const decodedHeader = jwt_decode(rresponse.access);
            if (decodedHeader["user_type"] === "SM") {
                localStorage.setItem("access_token", rresponse.access);
                localStorage.setItem("refresh_token", rresponse.refresh);
                localStorage.setItem("user_id", decodedHeader["user_id"]);
                localStorage.setItem("user_type", decodedHeader["user_type"]);
                localStorage.setItem("username", decodedHeader["username"]);
                return rresponse; 
            } else {
                return false;
            }
        } else if (rresponse.detail) {
            return rresponse;
            throw new Error(rresponse.detail);
        } else {
            throw new Error("Unknown error. Please try again.");
        }
    }
}

export async function RecursiveApiCall(eventName, params){}
export async function SingleTimeApiCall(eventName, params){}
